<template>
  <b-card title="Inventory List ">
    <!-- <b-modal
      id="promptDownload"
      ref="modal"
      v-model="promptDownload"
      title="Download"
      hide-footer
    >
    <strong>Select download file type</strong>
    <div style="display: table; margin: 0 auto;">
      <download-excel :data="inventory" :fields="filter" name="Inventory.xls" class="download-border">
        <img src="@/assets/images/xls.png" height=100>
      </download-excel>
      <download-excel :data="inventory" :fields="filter" type="csv" name="Inventory.csv" class="download-border">
        <img src="@/assets/images/csv.png" height=100>
      </download-excel>
    </div>
    </b-modal> -->
    <b-modal
      id="promptDownload"
      ref="modal"
      v-model="promptDownload"
      title="Download"
      hide-footer
    >
    <strong v-if="inventoryToDownload.length<=0">Loading Data...</strong>
    <strong v-else>Loading data finished, total data is {{inventoryToDownload.length}}</strong>
    <div>
      <strong> Progress {{ progressDownload }} %</strong>
    </div>
    <div style="display: table; margin: 0 auto;">
      <b-button :disabled="inventoryToDownload.length<totalRows" variant="primary" style="margin-top:20px">
        <download-excel  :data="inventoryToDownload" :fields="filter" name="Inventory.xls" class="download-border">download</download-excel>  
      </b-button>
    </div>
    </b-modal> 
    <b-col cols="12">
      <b-row>
        <b-col cols="5">
          Start Date
          <b-form-datepicker :disabled="disabledButton"  @input="(value) => fireFiltering('startDate', value)" :max="endDate" :min="minmin" v-model="startDate"/>
        </b-col>
        <b-col cols="5">
          End Date
          <b-form-datepicker :disabled="disabledButton" @input="(value) => fireFiltering('endDate', value)" :max="maxDate" v-model="endDate"/>
        </b-col>
        <b-col cols="2">
            <!-- <b-button variant="primary" style="margin-top:20px" @click="promptDownload=true">
              <feather-icon size="1x" icon="DownloadIcon" /> Download
            </b-button> -->
            <b-button variant="primary" style="margin-top:20px" @click="openDownloadModal">
            Download 
            </b-button>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col cols="4"/>
        <b-col cols="4">
          <b-form-input
            @input="(value) => sapSoFiltering(value)"
            v-model="soNumber"
            type="search"
            placeholder="Search by SO Number"
          />
        </b-col>
        <b-col cols="4">
          <b-form-input
            @input="(value) => sapDoFiltering(value)"
            v-model="search"
            type="search"
            placeholder="Search by DO Number"
          />
        </b-col>

        <b-col style="margin: 15px 0 0 0" cols="12">
          <b-table
            :items="mappedInventory"
            :fields="fields"
            :busy.sync="busyLoading"
            responsive
            show-empty
            empty-text="Data Not Found"
          >
          <template v-slot:cell(deliveryDate)="row">
            {{ dates(row.item.deliveryDate) }}
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <!-- <template #cell(mixStats)="row">
            {{row.item.deliveryStatus?row.item.deliveryStatus:row.item.status}}
          </template> -->
          </b-table>
        </b-col>

{{hapus}}
        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import * as XLSX from 'xlsx'
import moment from 'moment';
import axios from '@/axios';
import lodash from 'lodash';
import { userAccess, dateFormat,convertPDFtoBase64 } from "@/utils/utils";

export default {
  data() {
    return {
      fields: [
        { key: 'customerPo', label: "Customer PO", filterable: true, sortable: true},
        { key: 'rspOrderNumber', label: 'RSP Order Number', filterable: true, sortable: true },
        { key: 'sapSo', label: 'SAP SO', filterable: true, sortable: true, },
        { key: 'sapDo', label: 'SAP DO', filterable: true, sortable: true },
        { key: 'deliveryDate', label: 'DO Date', filterable: true, sortable: true },
        { key: 'deliveryStatus', label: 'Status', filterable: true, sortable: true },
        { key: 'scanStatus', label: 'Scan Status', filterable: true, sortable: true },
        { key: 'barcode', label: 'Barcode', filterable: true, sortable: true },
        { key: 'serial', label: 'Serial', filterable: true, sortable: true },
        { key: 'itemModel', label: 'Model', filterable: true, sortable: true },
      ],      
      filter: {
        'Order Number' : 'orderNumber',
        'SAP SO' : 'sapSo',
        'SAP DO' : 'sapDo',
        'Order Date' : 'orderDate',
        'Delivery Date' :'deliveryDate',
        'Priority' : 'priority',
        'Shipping Point' : 'shippingPoint',
        'Customer Name1' : 'CustomerName1',
        'Customer Name2' : 'CustomerName2',
        'Customer Name3' : 'CustomerName3',
        'Customer Name4' : 'CustomerName4',
        'Delivery Address1' : 'deliveryAddress1',
        'Delivery Address2' : 'deliveryAddress2',
        'Delivery Address3' : 'deliveryAddress3',
        'Delivery Status' : 'deliveryStatus',
        'Status' : 'status',
        'Sales Person Code' : 'salesPersonCode',
        'Salesman' : 'salesman',
        'Project Name' : 'projectName',
        'Phier' : 'phier',
        'Cust Group' : 'custgroup',
        'Item Model' : 'itemModel',
        'Description' : 'description',
        // 'Phlvl1' : 'phlvl1',
        'Phlvl1' : {field: "phlvl1", callback: value => {return ` ​${value}`}},
        'Phlvl2' : {field: "phlvl2", callback: value => {return ` ​${value}`}},
        'Serial' : 'serial',
        'Barcode' : 'barcode',
        'Scan Status' : 'scanStatus',
        'Scan Time' : 'scanTime',
        'Scan User' : 'scanUser.email',
        'Unscan Time' : 'unscanTime',
        'Unscan User' : 'scanUser.email'
      },
      inventoryToDownload:[],
      search: '',
      soNumber: '',
      currentPage: 1,
      perPage: 10,
      totalData: 1,

      minmin:'',
      startDate: '',
      endDate: '',
      maxDate: '',
      busyLoading: true,
      totalRows:1,
      promptDownload: false,

      //download numbers
      downPage:0,
      dataDownload:0,
      inventoryToDownload:[],
      disabledButton: false,
    }
  },
  mounted() {
    
  },
  computed: {
    progressDownload () {
        let downloaded = this.inventoryToDownload.length
        let dataToDownload = this.totalRows
        return Math.ceil((downloaded/dataToDownload)*(100))
    },
    ...mapGetters({
      inventory : 'report/inventory'
    }),
    ...mapState({
      filterOrder: (state) => {
        if (state.filter.filterContainer['report-inventory-list'] !== undefined) {
          let orderFilter = state.filter.filterContainer['report-inventory-list'];
          return orderFilter;
        }

        return {};
      },
    }),
    mappedInventory(){
      return this.inventory.map(item => {
        return {
          ...item,
          rspOrderNumber: item.orderNumber.includes('-') &&  item.orderNumber.startsWith('RSP')? item.orderNumber.substring(0, 12) : '',
          customerPo: item.orderNumber.includes('-') &&  item.orderNumber.startsWith('RSP') ? item.orderNumber.substring(11) : item.orderNumber
        }
      })
    },
   reactiveStartDate: {
      get() {
        if(this.filterOrder.startDate === undefined){
          this.filterOrder.startDate = this.startDate
        }
      
        return this.filterOrder.startDate;
      },
      set() {
        this.updateFilterInventory({
          name: 'report-inventory-list',
          data: {
            startDate: this.filterOrder.startDate
          }
        });

      }
    },
    hapus(){
      // console.log('this.endDate',this.endDate);
    },
    reactiveEndDate: {
      get() {
        if(this.filterOrder.endDate === undefined){
          this.filterOrder.endDate = this.endDate;
        }
        return this.filterOrder.endDate;
      },
      set() {
        this.updateFilterInventory({
          name: 'report-inventory-list',
          data: {
            endDate: this.filterOrder.endDate
          }
        });

      }
    },   
  },
  methods: {
    ...mapActions({
      inventoryList : 'report/getInventory',
      getFilterInventory: 'filter/getFilter',
      updateFilterInventory: 'filter/updateFilter'
    }),
    async openDownloadModal(){
      this.downPage = 0
      this.dataDownload = 0
      this.inventoryToDownload = []
      this.promptDownload=true;
      let page = 0
      var dataDownload=this.dataDownload
      while (dataDownload < this.totalRows) {
        console.info('total data now ',dataDownload,'all data ',this.totalRows)
        let dataProcess = []
        page++
        this.downPage = page  

        // setTimeout(() => {
        console.log('go');
         await this.inventoryList({
            startDate : this.reactiveStartDate, 
            endDate : this.reactiveEndDate,
            forDownload:true,
            sapDo:this.search,
            sapSo: this.soNumber,
            page: page, 
            entry: 500,
          
          }).then((res) => {
        // this.warrantyToDownload=data.data;
        dataProcess=res.data;
        // console.log(dataProcess)
        
        // split into each item 
        this.inventoryToDownload.push(...dataProcess)
        dataDownload += dataProcess.length
        this.dataDownload = dataDownload
       
      });  
    }
      

    },
    onPageChange(page) {
      this.currentPage=page;

      this.inventoryList({
        startDate : this.reactiveStartDate, 
        endDate : this.reactiveEndDate,
        page: this.currentPage, 
        entry: this.perPage,
        sapDo: this.search,
        sapSo: this.soNumber
        
       
      }).then((data) => this.processMetadata(data.metadata));
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.busyLoading = false;
    },
    saveFilter(key, value) { //save updated filter
      let tempFilter = this.filterOrder; 

      if (value === null) {
        tempFilter[key] = undefined;
      } else {
        tempFilter[key] = value;
      }
      
      this.updateFilterInventory({ //save to store named is "filter"
        name: 'report-inventory-list',
        data: { ...tempFilter,entry: this.perPage,page:1 }
      });
    },
    fireFiltering(key, value) {
      this.saveFilter(key, value); //filter saved date
      
      let filter = this.filterOrder; 
      filter.sapDo = this.search
      filter.sapSo = this.soNumber
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const minDate = new Date(this.endDate)
      minDate.setMonth(minDate.getMonth() - 1)
      minDate.setDate(minDate.getDate())
      this.minmin = minDate

      this.busyLoading = true;
      this.inventoryList({ ...filter,entry: this.perPage,page:1}).then((data) => {
        this.processMetadata(data.metadata)
      });
    },
    sapDoFiltering: lodash.debounce(function (value){
      this.search=value;
      this.busyLoading = true
      if (this.search!== "") {
        this.disabledButton = true
        this.inventoryList({
          page: 1, 
          entry: this.perPage,
          sapDo:this.search,
          sapSo: this.soNumber
        }).then((data) => {
          this.processMetadata(data.metadata)
          this.busyLoading = false
        });  
      }else{
        this.disabledButton = false
        this.busyLoading = true
        this.inventoryList({
          page: 1, 
          entry: this.perPage,
          startDate : this.reactiveStartDate, 
          endDate : this.reactiveEndDate,
          sapDo:this.search,
          sapSo: this.soNumber
        }).then((data) => {
          this.processMetadata(data.metadata)
          this.busyLoading = false
        });  
        this.busyLoading = false
      }
    },500),

    sapSoFiltering: lodash.debounce(function (value){
      this.soNumber=value;
      this.busyLoading = true
      if (this.soNumber!== "") {
        this.disabledButton = true
        this.inventoryList({
          page: 1, 
          entry: this.perPage,
          sapDo:this.search,
          sapSo: this.soNumber
        }).then((data) => {
          this.processMetadata(data.metadata)
          this.busyLoading = false
        });  
      }else{
        this.disabledButton = false
        this.busyLoading = true
        this.inventoryList({
          page: 1, 
          entry: this.perPage,
          startDate : this.reactiveStartDate, 
          endDate : this.reactiveEndDate,
          sapDo:this.search,
          sapSo: this.soNumber
        }).then((data) => {
          this.processMetadata(data.metadata)
          this.busyLoading = false
        });  
        this.busyLoading = false
      }
    },500),

    dates(date) {
      return dateFormat(date);
    },
  },
  created() {
    // Setup Store To Save Filtering Information
    this.getFilterInventory('report-inventory-list');

    let now = new Date();
    let yearAgo = new Date(now.getFullYear()-1, now.getMonth()+1, now.getDate())
    
    let monthAgo= new Date();
    monthAgo.setDate(monthAgo.getDate()-7);

    
    this.maxDate = now;
    this.startDate = monthAgo;
    this.endDate = now

    const minDate = new Date(this.endDate)
    minDate.setMonth(minDate.getMonth() - 1)
    minDate.setDate(minDate.getDate())
    this.minmin = minDate

    document.title = 'Inventory Report | RSP'

    this.inventoryList({
      startDate : this.reactiveStartDate, 
      endDate : this.reactiveEndDate,
      page: this.currentPage, 
      entry: this.perPage,
       
      }).then((data) => this.processMetadata(data.metadata));
  },
}
</script>
